"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilitySteps = void 0;
exports.FacilitySteps = [{
        content: 'joyride:summary.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom',
        target: '.joyride-facilityScrollBlock .joyride-summary'
    }, {
        content: 'joyride:create_training_plan.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'right',
        target: '.joyride-createTrainingPlan'
    }, {
        content: 'joyride:training_performance.content',
        locale: {
            skip: 'joyride:skip',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'top',
        target: '.joyride-trainingList'
    }];
