"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_FORMAT = exports.REPORT_VARIANT = exports.REPORT_TYPE = exports.MAX_TRIAL_REPORT_FACTORIES = exports.PRINT_STATUS = void 0;
exports.PRINT_STATUS = {
    idle: 0,
    previewing: 1,
    printing: 2
};
exports.MAX_TRIAL_REPORT_FACTORIES = 5;
exports.REPORT_TYPE = Object.freeze({
    organization: 'organization',
    brand: 'brand',
    customView: 'custom-view',
    trial: 'trial',
    facility: 'facility',
    program: 'program'
});
exports.REPORT_VARIANT = Object.freeze({
    go: 'go',
    core: 'core'
});
exports.REPORT_FORMAT = Object.freeze({
    pdf: 'pdf'
});
