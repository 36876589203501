"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReminderType = void 0;
const utils_1 = require("../../../../../utils");
const i18n_1 = require("../../../../../i18n");
exports.ReminderType = function (ReminderType) {
    ReminderType["Invite"] = "invite";
    ReminderType["Reminder"] = "reminder";
    return ReminderType;
}({});
var sendSupplierReminderEmail = function sendSupplierReminderEmail(_ref) {
    var type = _ref.type, relationId = _ref.relationId;
    return utils_1.post({
        endpoint: 'user-accounts/remind-supplier',
        payload: {
            type: type,
            relationId: relationId
        },
        language: i18n_1.getLanguage()
    });
};
exports.default = sendSupplierReminderEmail;
