"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getLocalCsvDelimiter = function getLocalCsvDelimiter() {
    var list = ['a', 'b'];
    var str = '';
    if (list.toLocaleString) {
        str = list.toLocaleString();
        if (str.indexOf(';') > 0 && str.indexOf(',') === -1) {
            return ';';
        }
    }
    return ',';
};
var convertToCsv = function convertToCsv(data) {
    var csv = '';
    var csvDelimiter = getLocalCsvDelimiter();
    data.forEach(function (row) {
        if (row.length) {
            csv += row.join("".concat(csvDelimiter)).replace('#', ' ');
            csv += '\n';
        }
    });
    return csv;
};
exports.default = convertToCsv;
