"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceDistributionColors = exports.PerformanceLevelColors = exports.PerformanceTrendColors = void 0;
exports.PerformanceTrendColors = ['#222222', '#218837', '#215cfd', '#9a9693', '#003cd4', '#28a247', '#abc3ff', '#104e1f', '#cdc9c5', '#615e5c', '#8edba2', '#052264', '#fe6726'];
exports.PerformanceLevelColors = {
    high: '#D4ECDA',
    medium: '#FFFDCA',
    low: '#FFE1D4'
};
exports.PerformanceDistributionColors = {
    concentration_range_1: '#D9D9D9',
    concentration_range_2: '#B3C7FE',
    concentration_range_3: '#86A6FE',
    concentration_range_4: '#215CFD',
    concentration_range_5: '#023EE3',
    concentration_range_6: '#012EA0'
};
