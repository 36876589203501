"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOYRIDE_TEAM_MANAGEMENT = exports.JOYRIDE_PERFORMANCE_BY_QUESTION = exports.JOYRIDE_FACILITY_TRAINING_PERFORMANCE_DETAILS = exports.JOYRIDE_FACILITIES_TRAINING_PERFORMANCE = exports.JOYRIDE_FACILITY_COMPLETED_TRAINING_PLANS_DETAIL = exports.JOYRIDE_FACILITY_ACTIVE_TRAINING_PLANS_DETAIL = exports.JOYRIDE_COMPLETED_TRAINING_PLANS = exports.JOYRIDE_ACTIVE_TRAINING_PLANS = exports.JOYRIDE_RESOURCES = exports.JOYRIDE_USER_LIST = exports.JOYRIDE_USER_PROFILE = exports.JOYRIDE_REPORT = exports.JOYRIDE_FACILITY_GO = exports.JOYRIDE_FACILITY = exports.JOYRIDE_FILTER_OVERVIEW = exports.JOYRIDE_OVERVIEW = exports.JOYRIDE_RUN_PAGES = void 0;
exports.JOYRIDE_RUN_PAGES = 'joyrideRunPages';
exports.JOYRIDE_OVERVIEW = 'joyrideOverview';
exports.JOYRIDE_FILTER_OVERVIEW = 'joyrideFilterOverview';
exports.JOYRIDE_FACILITY = 'joyrideFacility';
exports.JOYRIDE_FACILITY_GO = 'joyrideFacilityGo';
exports.JOYRIDE_REPORT = 'joyrideReport';
exports.JOYRIDE_USER_PROFILE = 'UserProfile';
exports.JOYRIDE_USER_LIST = 'joyrideUserAdministration';
exports.JOYRIDE_RESOURCES = 'joyrideResources';
exports.JOYRIDE_ACTIVE_TRAINING_PLANS = 'joyrideActiveTrainingPlans';
exports.JOYRIDE_COMPLETED_TRAINING_PLANS = 'joyrideCompletedTrainingPlans';
exports.JOYRIDE_FACILITY_ACTIVE_TRAINING_PLANS_DETAIL = 'joyrideFacilityActiveTrainingplanDetail';
exports.JOYRIDE_FACILITY_COMPLETED_TRAINING_PLANS_DETAIL = 'joyrideFacilityCompletedTrainingplanDetail';
exports.JOYRIDE_FACILITIES_TRAINING_PERFORMANCE = 'joyrideFacilitiesTrainingPerformance';
exports.JOYRIDE_FACILITY_TRAINING_PERFORMANCE_DETAILS = 'joyrideFacilityTrainingPerformanceDetails';
exports.JOYRIDE_PERFORMANCE_BY_QUESTION = 'joyridePerformanceByQuestion';
exports.JOYRIDE_TEAM_MANAGEMENT = 'joyrideTeamManagement';
