"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteSupplierStrategy = void 0;
exports.InviteSupplierStrategy = function (InviteSupplierStrategy) {
    InviteSupplierStrategy["InviteSupplierByEmail"] = "invite-supplier-by-email";
    InviteSupplierStrategy["LinkSupplierByInvitationCode"] = "link-supplier-by-invitation-code";
    InviteSupplierStrategy["LinkExistingSupplierToNewCreatedFacility"] = "link-existing-supplier-to-new-created-facility";
    InviteSupplierStrategy["LinkExistingSupplierToExistingFacility"] = "link-existing-supplier-to-existing-facility";
    InviteSupplierStrategy["AcceptSupplierRequest"] = "accept-supplier-request";
    return InviteSupplierStrategy;
}({});
