"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../../../../../utils");
var updateFacility = function updateFacility(facilityId, teamSize) {
    return utils_1.patch({
        endpoint: "factories/".concat(facilityId),
        payload: {
            teamSize: teamSize
        }
    });
};
exports.default = updateFacility;
