"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabKey = exports.Action = exports.HOMEWORK_RECIPIENT = exports.DEFAULT_POPOVER_INFO = void 0;
const PopoverWrapper_1 = require("../../../../../components/PopoverWrapper");
exports.DEFAULT_POPOVER_INFO = {
    isOpen: false,
    status: PopoverWrapper_1.Status.NOT_ACTIVE,
    message: ''
};
exports.HOMEWORK_RECIPIENT = config.homeworkRecipient || '';
exports.Action = function (Action) {
    Action["Remind"] = "remind";
    Action["Revoke"] = "revoke";
    Action["Delete"] = "delete";
    Action["ResendInvitation"] = "resend_invitation";
    Action["CopyTrainingLink"] = "copy_training_link";
    Action["AcceptSupplierRequest"] = "accept_supplier_request";
    Action["DeclineSupplierRequest"] = "decline_supplier_request";
    Action["EnableTraining"] = "enable_training";
    Action["DisableTraining"] = "disable_training";
    return Action;
}({});
exports.TabKey = function (TabKey) {
    TabKey["YourColleagues"] = "your-colleagues";
    TabKey["YourSuppliers"] = "your-suppliers";
    TabKey["YourSubSuppliers"] = "your-sub-suppliers";
    return TabKey;
}({});
