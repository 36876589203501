"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var readAsDataURLAsync = function readAsDataURLAsync(file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (fileLoadedEvent) {
            var _fileLoadedEvent$targ;
            resolve((_fileLoadedEvent$targ = fileLoadedEvent.target) === null || _fileLoadedEvent$targ === void 0 ? void 0 : _fileLoadedEvent$targ.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};
exports.default = readAsDataURLAsync;
