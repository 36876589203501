"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldAskAdditionalInformationStep = exports.checkShouldAskFacilityBrandId = void 0;
const constants_1 = require("../data/constants");
const enums_1 = require("../data/enums");
exports.checkShouldAskFacilityBrandId = function checkShouldAskFacilityBrandId(_ref) {
    var _signupFeaturePackage, _facilityOwnerAccount;
    var user = _ref.user, signupFeaturePackage = _ref.signupFeaturePackage;
    if (!user || !signupFeaturePackage || !((_signupFeaturePackage = signupFeaturePackage.signupSteps) !== null && _signupFeaturePackage !== void 0 && _signupFeaturePackage.some(function (step) {
        var _step$features;
        return step.id === constants_1.SignUpSteps.create_factory && ((_step$features = step.features) === null || _step$features === void 0 ? void 0 : _step$features.some(function (f) {
            return f === constants_1.Features.AskFacilityBrandId;
        }));
    }))) {
        return false;
    }
    var accounts = user.accounts, featurePackage = user.featurePackage, factories = user.factories, role = user.role;
    var isOwnerUser = role === enums_1.UserRole.ACCOUNT_OWNER;
    var facilityOwnerAccount = isOwnerUser && (!featurePackage || (featurePackage === null || featurePackage === void 0 ? void 0 : featurePackage.accountType) === enums_1.AccountType.TRAINING_FACILITY) ? accounts.find(function (acc) {
        var _acc$factories;
        return (_acc$factories = acc.factories) === null || _acc$factories === void 0 ? void 0 : _acc$factories.length;
    }) : undefined;
    if (!facilityOwnerAccount) {
        return false;
    }
    var hasFacilityBrandId = (_facilityOwnerAccount = facilityOwnerAccount.factories) === null || _facilityOwnerAccount === void 0 ? void 0 : _facilityOwnerAccount.every(function (facilityId) {
        return factories.some(function (f) {
            var _f$facilityBrandIds;
            return f._id.toString() === facilityId.toString() && ((_f$facilityBrandIds = f.facilityBrandIds) === null || _f$facilityBrandIds === void 0 ? void 0 : _f$facilityBrandIds.some(function (fbi) {
                return fbi.id && signupFeaturePackage.brand === fbi.brand;
            }));
        });
    });
    return !hasFacilityBrandId;
};
exports.shouldAskAdditionalInformationStep = function shouldAskAdditionalInformationStep(signupFeaturePackage) {
    var _signupFeaturePackage2;
    return signupFeaturePackage === null || signupFeaturePackage === void 0 ? void 0 : (_signupFeaturePackage2 = signupFeaturePackage.signupSteps) === null || _signupFeaturePackage2 === void 0 ? void 0 : _signupFeaturePackage2.some(function (step) {
        return step.id === constants_1.SignUpSteps.ask_additional_information;
    });
};
