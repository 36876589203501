"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldNames = void 0;
exports.FieldNames = function (FieldNames) {
    FieldNames["ExcludedDates"] = "excludedDates";
    FieldNames["TrainingSeriesId"] = "trainingSeriesId";
    FieldNames["Level"] = "level";
    FieldNames["EmployeesToTrain"] = "employeesToTrain";
    FieldNames["BaselineRequiredSessions"] = "baselineRequiredSessions";
    FieldNames["EndlineRequiredSessions"] = "endlineRequiredSessions";
    FieldNames["LearningModules"] = "learningModules";
    FieldNames["TimePeriodSelect"] = "timePeriodSelect";
    return FieldNames;
}({});
