"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceByQuestionSteps = void 0;
exports.PerformanceByQuestionSteps = [{
        content: 'joyride:switch_between_basic_and_advance.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'bottom-end',
        target: '.joyride-switchBetweenBasicAndAdvance'
    }, {
        content: 'joyride:question_and_correct_answer.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'top',
        target: '.joyride-questionAndCorrectAnswer'
    }, {
        content: 'joyride:performance_result_of_question.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'top-end',
        target: '.joyride-performanceResultOfQuestion'
    }];
