"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportSteps = void 0;
exports.ReportSteps = [{
        content: 'joyride:pdf_csv.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        spotlightPadding: 0,
        placement: 'top',
        disableBeacon: true,
        target: '.joyride-reportType',
        styles: {
            options: {
                width: 500
            }
        }
    }, {
        content: 'joyride:custom_view_report.content',
        locale: {
            skip: 'joyride:skip',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom',
        target: '.custom_view'
    }];
