"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierStatus = void 0;
exports.SupplierStatus = function (SupplierStatus) {
    SupplierStatus["INVITE_PENDING"] = "invite_pending";
    SupplierStatus["REQUEST_PENDING"] = "request_pending";
    SupplierStatus["NOT_STARTED"] = "not_started";
    SupplierStatus["IN_PROGRESS"] = "in_progress";
    SupplierStatus["REGISTERED"] = "registered";
    SupplierStatus["REVOKED"] = "revoked";
    return SupplierStatus;
}({});
