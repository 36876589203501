"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQrcodeEnvironment = exports.getCharSafeFacilityName = void 0;
exports.getCharSafeFacilityName = function getCharSafeFacilityName(facilityName) {
    return facilityName.replace(/[^\w ]/g, '').replace(/\s+/g, '_');
};
exports.getQrcodeEnvironment = function getQrcodeEnvironment(_ref) {
    var configName = _ref.configName, facilityEnvironment = _ref.facilityEnvironment;
    if (facilityEnvironment === 'dev') {
        return "".concat(configName, "/").concat(facilityEnvironment);
    }
    return configName;
};
