"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../../../../../components/QrCode/utils");
const utils_2 = require("../../../../../utils");
var getTrainingLink = function getTrainingLink(qrCodeId) {
    var trainingLink = utils_2.getWebBaseTrainingUrl({
        id: qrCodeId,
        environment: utils_1.getQrcodeEnvironment({
            configName: config.configName,
            facilityEnvironment: 'production'
        })
    }, {
        trainingParticipationType: null
    }, {
        hideIntroVideo: true
    });
    return trainingLink;
};
exports.default = getTrainingLink;
