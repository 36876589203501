"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDidUpdateEffect = void 0;
const react_1 = require("react");
exports.useDidUpdateEffect = function useDidUpdateEffect(effect, dependencies) {
    var didMount = react_1.useRef(false);
    react_1.useEffect(function () {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }
        effect();
    }, dependencies);
};
