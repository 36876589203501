"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilityActiveTrainingPlansDetailSteps = void 0;
exports.FacilityActiveTrainingPlansDetailSteps = [{
        content: 'joyride:active_facility_training_plans_detail.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'top-start',
        target: '.joyride-facilityDetail'
    }, {
        content: 'joyride:training_plan_status.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-trainingPlanStatus'
    }, {
        content: 'joyride:progress_legend.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'auto',
        target: '.joyride-progressLegend'
    }, {
        content: 'joyride:detail_view_switch.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-detailedViewSwitch'
    }];
