"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilityTraningPerformanceDetailsSteps = void 0;
exports.FacilityTraningPerformanceDetailsSteps = [{
        content: 'joyride:facility_training_performance_details.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'top',
        target: '.joyride-facilityTrainingPerformanceDetails'
    }, {
        content: 'joyride:filter_training_programs.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom-end',
        target: '.joyride-filterTrainingPrograms'
    }, {
        content: 'joyride:performance_by_module.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'top',
        target: '.joyride-performanceByModule'
    }, {
        content: 'joyride:performance_result_for_each_question.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom-end',
        target: '.joyride-performanceResultForEachQuestion'
    }, {
        content: 'joyride:knowledge_change_by_learning_module.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'bottom-end',
        target: '.joyride-knowledgeChangeByLearningModule'
    }, {
        content: 'joyride:knowledge_change_by_each_question.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:next'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom-end',
        target: '.joyride-knowledgeChangeByEachQuestion'
    }];
