"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchParams = void 0;
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
function useSearchParams() {
    var _useLocation = react_router_dom_1.useLocation(), search = _useLocation.search;
    return react_1.useMemo(function () {
        return new URLSearchParams(search);
    }, [search]);
}
exports.useSearchParams = useSearchParams;
