"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourcesSteps = void 0;
exports.ResourcesSteps = [{
        content: 'joyride:resources.resources_list.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-resourcesList'
    }, {
        content: 'joyride:resources.download_resource.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-firstResource'
    }, {
        content: 'joyride:resources.download_all_resource.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-downloadAllResource'
    }, {
        content: 'joyride:resources.change_resource_language.content',
        locale: {
            back: 'joyride:back',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'left',
        target: '.joyride-resourceLanguage'
    }];
