"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryUsers = void 0;
const apollo_1 = require("../../../../../data/apollo");
const queries_1 = require("../../../../../data/queries");
exports.queryUsers = function queryUsers() {
    var _useQuery = apollo_1.useQuery({
        query: queries_1.USER_DATA,
        variables: {
            roles: ['quizrr_employee', 'admin'],
            dynamicsSystemUserId: true
        }
    }), loading = _useQuery.loading, data = _useQuery.data;
    return {
        users: data === null || data === void 0 ? void 0 : data.users,
        loading: loading
    };
};
