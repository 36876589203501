"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWebBaseTrainingUrl = exports.getAppTrainingUrl = void 0;
exports.getAppTrainingUrl = function getAppTrainingUrl(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var encodedTrainingParams = window.btoa(JSON.stringify(trainingParams));
    var encodedSettings = window.btoa(encodeURIComponent(JSON.stringify(importSettings)));
    var hideIntroVideo = appSettings.hideIntroVideo, skipOnboarding = appSettings.skipOnboarding;
    var skipOnboardingParamString = skipOnboarding ? "&skipOnboarding=".concat(skipOnboarding) : '';
    return "".concat(config.webBasedTrainingUrl, "/app/?qrcode=").concat(encodedTrainingParams, "&settings=").concat(encodedSettings).concat(hideIntroVideo ? "&hideIntroVideo=".concat(hideIntroVideo) : '').concat(skipOnboardingParamString);
};
exports.getWebBaseTrainingUrl = function getWebBaseTrainingUrl(trainingParams, importSettings) {
    var appSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var encodedTrainingParams = window.btoa(JSON.stringify(trainingParams));
    var encodedSettings = window.btoa(encodeURIComponent(JSON.stringify(importSettings)));
    var hideIntroVideo = appSettings.hideIntroVideo;
    return "".concat(window.location.origin, "/training/?qrcode=").concat(encodedTrainingParams, "&settings=").concat(encodedSettings).concat(hideIntroVideo ? "&hideIntroVideo=".concat(hideIntroVideo) : '', "\n  ");
};
