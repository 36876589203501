"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTextSearch = void 0;
const useTextSearch_1 = require("../../../../../hooks/useTextSearch");
exports.useTextSearch = function useTextSearch(items) {
    return useTextSearch_1.useTextSearch(items, function (code, input) {
        var _code$invitingBrand;
        return ((_code$invitingBrand = code.invitingBrand) === null || _code$invitingBrand === void 0 ? void 0 : _code$invitingBrand.name.toLowerCase().indexOf(input.toLowerCase())) !== -1;
    });
};
