"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamSize = exports.MAX_INTEGER_NUMBER = exports.MemberTrainingStatus = void 0;
exports.MemberTrainingStatus = Object.freeze({
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    INVITE_PENDING: 'invite_pending',
    REQUEST_PENDING: 'request_pending',
    REVOKED: 'revoked',
    REGISTERED: 'registered'
});
exports.MAX_INTEGER_NUMBER = 65536;
exports.TeamSize = Object.freeze({
    10: '0 - 10 ',
    50: '11 - 50',
    200: '51 - 200',
    500: '201 - 500',
    1000: '501 - 1000',
    65536: '1000+'
});
