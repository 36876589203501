"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveTraningPlansSteps = void 0;
exports.ActiveTraningPlansSteps = [{
        content: 'joyride:active_training_plans.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-facilitiesTrainingPlansStats',
        maxWidth: '240px'
    }, {
        content: 'joyride:facility_active_training_plans_card.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'right',
        target: '.joyride-facilityCard'
    }, {
        content: 'joyride:sort_bar.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-sortBar'
    }, {
        content: 'joyride:search_bar.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-searchBar'
    }, {
        content: 'joyride:show_off_track_training_plans.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'left',
        target: '.joyride-offTrackSwitch'
    }, {
        content: 'joyride:filter_facility.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'right',
        target: '.joyride-filterFacilities'
    }];
