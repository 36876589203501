"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpFlows = exports.emailRegex = exports.Features = exports.hiddenMarketNames = exports.globalMarketName = exports.signInSteps = exports.playerSignUpSteps = exports.SignUpSteps = void 0;
exports.SignUpSteps = Object.freeze({
    sign_up: 'sign-up',
    verify: 'verify',
    create_factory: 'create-factory',
    create_facility_team: 'create-facility-team',
    create_local_training_team: 'create-local-training-team',
    invite_members: 'invite-members',
    ask_additional_information: 'ask-additional-information'
});
exports.playerSignUpSteps = Object.freeze({
    sign_up: 0,
    verify: 1,
    finish: 2
});
exports.signInSteps = Object.freeze({
    sign_in: 0,
    verify: 1,
    finish: 2
});
exports.globalMarketName = 'global';
exports.hiddenMarketNames = ['other', exports.globalMarketName];
exports.Features = {
    AskTeamSize: 'ask-team-size',
    AskFactorySize: 'ask-factory-size',
    AskOwnerInfo: 'ask-owner-info',
    AskFacilityBrandId: 'ask-facility-brand-id',
    AskFacilityBrandIdOptional: 'ask-facility-brand-id-optional',
    CreateFactory: 'create-factory',
    CreateTeam: 'create-team',
    CreateLocalTrainingTeam: 'create-local-training-team',
    InvitePlayerByEmail: 'invite-player-by-email',
    InvitePlayerByLink: 'invite-player-by-link',
    InviteNonEmailPlayer: 'invite-non-email-player',
    InviteTeamAdminByEmail: 'invite-team-admin-by-email',
    InviteTeamAdminByLink: 'invite-team-admin-by-link',
    InviteSupplier: 'invite-supplier',
    InviteSubSupplier: 'invite-sub-supplier',
    AddSupplierInvitationCode: 'add-supplier-invitation-code',
    InviteLocalManagerByLink: 'invite-local-manager-by-link',
    InviteLocalManagerByEmail: 'invite-local-manager-by-email',
    InviteLocalTrainerByLink: 'invite-local-trainer-by-link',
    InviteLocalTrainerByEmail: 'invite-local-trainer-by-email',
    InviteBrandOwnerByEmail: 'invite-brand-owner-by-email',
    ShowChartData: 'show-chart-data',
    ShowReportData: 'show-report-data',
    ShowFactoryData: 'show-factory-data',
    ShowTeamData: 'show-team-data',
    SubmitHomework: 'submit-homework',
    ManageResources: 'manage-resources',
    HideIntroVideo: 'hide-intro-video',
    DownloadTrainingProgressReport: 'download-training-progress-report',
    IgnorePerformanceThreshold: 'ignore-performance-threshold',
    ChangeUsersPermission: 'change-users-permission',
    DownloadSupplierTrainingReport: 'download-supplier-training-report',
    UpdateQrCode: 'update-qr-code',
    InviteFacilityOwnerByEmail: 'invite-facility-owner-by-email',
    copySupplierNonEmailTrainingLink: 'copy-supplier-non-email-training-link'
};
exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
exports.SignUpFlows = function (SignUpFlows) {
    SignUpFlows["IOM"] = "IOM";
    SignUpFlows["Tatwin"] = "Tatwin";
    SignUpFlows["AIM"] = "AIM";
    SignUpFlows["AuroraCLUS"] = "Aurora_CLUS";
    SignUpFlows["AuroraGSCS"] = "Aurora_GSCS";
    SignUpFlows["AuroraWE"] = "Aurora_WE";
    SignUpFlows["HRDD"] = "HRDD";
    SignUpFlows["ULESR"] = "UL_ESR";
    SignUpFlows["ARTEMIS"] = "Artemis";
    return SignUpFlows;
}({});
