"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubsectorTranslationKey = void 0;
exports.getSubsectorTranslationKey = function getSubsectorTranslationKey(prefix, subSectorKey) {
    var translationKey = "".concat(prefix, "_").concat(subSectorKey);
    switch (subSectorKey) {
        case 'other':
            translationKey = 'other';
            break;
        case 'dont_know':
            translationKey = 'dont_know';
            break;
        case 'all':
            translationKey = 'all';
            break;
        default:
            break;
    }
    return translationKey;
};
