"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeamManagementSteps = exports.IOMTeamManagementSteps = void 0;
const constants_1 = require("../../../../../../data/constants");
const enums_1 = require("../../../../../../data/enums");
var locale = {
    skip: 'joyride:skip',
    back: 'joyride:back',
    next: 'joyride:next',
    last: 'joyride:finish'
};
exports.IOMTeamManagementSteps = [{
        content: 'joyride:team_management.iom.overview.content',
        locale: locale,
        disableBeacon: true,
        disableScrolling: true,
        placement: 'top-start',
        target: '.joyride-accountScrollBlock'
    }, {
        content: 'joyride:team_management.iom.your_colleagues.content',
        locale: locale,
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom',
        target: '.your-colleagues'
    }, {
        content: 'joyride:team_management.iom.invite_colleagues.content',
        locale: locale,
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'left',
        target: '.joyride-inviteColleagues'
    }, {
        content: 'joyride:team_management.iom.your_suppliers.content',
        locale: locale,
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'bottom',
        target: '.your-suppliers'
    }, {
        content: 'joyride:team_management.iom.submit_homework.content',
        locale: locale,
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'right',
        target: '.joyride-submitHomework'
    }, {
        content: 'joyride:team_management.iom.add_invitation_code.content',
        locale: locale,
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'right',
        target: '.joyride-addInvitationCode'
    }];
exports.getTeamManagementSteps = function getTeamManagementSteps(_ref) {
    var signupFlow = _ref.signupFlow, accountType = _ref.accountType, role = _ref.role;
    if (signupFlow === constants_1.SignUpFlows.IOM && accountType === enums_1.AccountType.TRAINING_FACILITY && role === enums_1.UserRole.ACCOUNT_OWNER) {
        return exports.IOMTeamManagementSteps;
    }
    return [];
};
