"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitChartLabel = void 0;
exports.splitChartLabel = function splitChartLabel(_ref) {
    var label = _ref.label, maxLengthPerLine = _ref.maxLengthPerLine;
    var splitValues = function () {
        if (label.length <= maxLengthPerLine) {
            return [label];
        }
        var firstLineTemp = label.slice(0, maxLengthPerLine + 1);
        if (firstLineTemp.indexOf(' ') === -1 && firstLineTemp.indexOf('-') === -1) {
            var _secondLine = label.slice(maxLengthPerLine - 1);
            return ["".concat(label.slice(0, maxLengthPerLine - 1), "-"), _secondLine.length > maxLengthPerLine ? "".concat(_secondLine.slice(0, maxLengthPerLine - 1), "...") : _secondLine];
        }
        var splits = label.split(/(\s|-)/);
        var firstLine = '';
        var splitIndex = 0;
        while ("".concat(firstLine).concat(splits[splitIndex]).length < maxLengthPerLine) {
            firstLine = "".concat(firstLine).concat(splits[splitIndex]);
            splitIndex += 1;
        }
        var secondLine = label.slice(firstLine.length).trim();
        if (secondLine.length > maxLengthPerLine) {
            secondLine = "".concat(secondLine.slice(0, maxLengthPerLine - 1), "...");
        }
        return [firstLine, secondLine];
    }();
    return splitValues;
};
