"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Answer = exports.jobTitles = exports.FieldConfigType = void 0;
exports.FieldConfigType = function (FieldConfigType) {
    FieldConfigType["Select"] = "select";
    FieldConfigType["Radio"] = "radio";
    FieldConfigType["Datetime"] = "datetime";
    return FieldConfigType;
}({});
exports.jobTitles = ['business_development_manager', 'compliance_manager', 'esg_manager', 'hr_manager', 'procurement_manager', 'social_responsibility_manager', 'sustainability_manager', 'workforce_manager', 'other'];
exports.Answer = function (Answer) {
    Answer["Yes"] = "yes";
    Answer["No"] = "no";
    Answer["DontKnow"] = "dont_know";
    return Answer;
}({});
