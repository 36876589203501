"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierTypes = exports.SubSupplierTypes = exports.SupplyChainLevelValue = exports.reportColors = exports.dependedFieldsOfFacilityTypeFilter = exports.dependedFieldsOfSupplyChainLevelFilter = exports.dependedFieldsOfMarketFilter = exports.dependedFieldsOfBrandFilter = exports.dependedFieldsOfOrganizationFilter = exports.FilterValueKeys = void 0;
const qcl_1 = require("@quizrr/qcl");
exports.FilterValueKeys = function (FilterValueKeys) {
    FilterValueKeys["Organization"] = "organization";
    FilterValueKeys["Brand"] = "brand";
    FilterValueKeys["Market"] = "market";
    FilterValueKeys["SupplyChainLevel"] = "supplyChainLevel";
    FilterValueKeys["FacilityType"] = "facilityType";
    FilterValueKeys["Facility"] = "facility";
    FilterValueKeys["TrainingSeries"] = "trainingSeries";
    FilterValueKeys["BusinessLane"] = "businessLane";
    FilterValueKeys["TimePeriod"] = "timePeriod";
    return FilterValueKeys;
}({});
exports.dependedFieldsOfOrganizationFilter = [exports.FilterValueKeys.Brand, exports.FilterValueKeys.Market, exports.FilterValueKeys.SupplyChainLevel, exports.FilterValueKeys.FacilityType, exports.FilterValueKeys.Facility, exports.FilterValueKeys.TrainingSeries, exports.FilterValueKeys.BusinessLane];
exports.dependedFieldsOfBrandFilter = [exports.FilterValueKeys.Market, exports.FilterValueKeys.SupplyChainLevel, exports.FilterValueKeys.FacilityType, exports.FilterValueKeys.Facility, exports.FilterValueKeys.TrainingSeries, exports.FilterValueKeys.BusinessLane];
exports.dependedFieldsOfMarketFilter = [exports.FilterValueKeys.SupplyChainLevel, exports.FilterValueKeys.FacilityType, exports.FilterValueKeys.Facility, exports.FilterValueKeys.TrainingSeries, exports.FilterValueKeys.BusinessLane];
exports.dependedFieldsOfSupplyChainLevelFilter = [exports.FilterValueKeys.FacilityType, exports.FilterValueKeys.Facility, exports.FilterValueKeys.TrainingSeries, exports.FilterValueKeys.BusinessLane];
exports.dependedFieldsOfFacilityTypeFilter = [exports.FilterValueKeys.Facility, exports.FilterValueKeys.TrainingSeries, exports.FilterValueKeys.BusinessLane];
exports.reportColors = [qcl_1.theme.colors.blue500, qcl_1.theme.colors.blue300, qcl_1.theme.colors.gray300];
exports.SupplyChainLevelValue = function (SupplyChainLevelValue) {
    SupplyChainLevelValue["SUPPLIER"] = "supplier";
    SupplyChainLevelValue["SUB_SUPPLIER"] = "sub_supplier";
    return SupplyChainLevelValue;
}({});
exports.SubSupplierTypes = ['labor_agent'];
exports.SupplierTypes = ['logistics_hub', 'production_unit', 'training_unit', 'service_provider', 'retail_store'];
