"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStripeCustomer = exports.stripeSetDefaultPaymentMethod = exports.stripeDeletePaymentMethod = exports.stripeCreatePaymentMethod = exports.stripeUpdatePaymentMethod = exports.stripListPaymentMethods = exports.stripeCancelCustomerSubscription = exports.stripeListCustomerSubscriptions = exports.stripeListTransactions = exports.stripeCreateSubscription = void 0;
const utils_1 = require("../utils");
exports.stripeCreateSubscription = function stripeCreateSubscription(payload) {
    return utils_1.post({
        endpoint: 'payments/subscription',
        payload: payload,
        withAuth: false
    });
};
exports.stripeListTransactions = function stripeListTransactions(customerId) {
    return utils_1.get({
        endpoint: "payments/transactions?customerId=".concat(customerId)
    });
};
exports.stripeListCustomerSubscriptions = function stripeListCustomerSubscriptions(customerId) {
    return utils_1.get({
        endpoint: "payments/subscription?customerId=".concat(customerId)
    });
};
exports.stripeCancelCustomerSubscription = function stripeCancelCustomerSubscription(payload) {
    return utils_1.post({
        endpoint: 'payments/subscription/cancel',
        payload: payload
    });
};
exports.stripListPaymentMethods = function stripListPaymentMethods(customerId) {
    return utils_1.get({
        endpoint: "payments/payment-methods?customerId=".concat(customerId)
    });
};
exports.stripeUpdatePaymentMethod = function stripeUpdatePaymentMethod(payload) {
    return utils_1.post({
        endpoint: 'payments/payment-methods/update',
        payload: payload
    });
};
exports.stripeCreatePaymentMethod = function stripeCreatePaymentMethod(payload) {
    return utils_1.post({
        endpoint: 'payments/payment-methods',
        payload: payload
    });
};
exports.stripeDeletePaymentMethod = function stripeDeletePaymentMethod(paymentMethodId) {
    return utils_1.remove({
        endpoint: "payments/payment-methods/".concat(paymentMethodId)
    });
};
exports.stripeSetDefaultPaymentMethod = function stripeSetDefaultPaymentMethod(payload) {
    return utils_1.post({
        endpoint: 'payments/payment-methods/set-default',
        payload: payload
    });
};
exports.getStripeCustomer = function getStripeCustomer() {
    return utils_1.get({
        endpoint: "payments/get-customer"
    });
};
