"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getUnsignedContract = function getUnsignedContract(userProfile) {
    if (userProfile && userProfile.accounts) {
        var unsignedContractAccount = userProfile.accounts.find(function (a) {
            var _a$contract;
            return !((_a$contract = a.contract) !== null && _a$contract !== void 0 && _a$contract.isSigned);
        });
        return unsignedContractAccount === null || unsignedContractAccount === void 0 ? void 0 : unsignedContractAccount.contract;
    }
    return null;
};
exports.default = getUnsignedContract;
