"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldNames = void 0;
exports.FieldNames = function (FieldNames) {
    FieldNames["InvitingBrand"] = "invitingBrand";
    FieldNames["OperationOwner"] = "operationOwner";
    FieldNames["SaleOwner"] = "saleOwner";
    FieldNames["ContractPeriodByMonth"] = "contractPeriodByMonth";
    FieldNames["SetupFeeValue"] = "setupFeeValue";
    FieldNames["SetupFeeDiscountCampaignOption"] = "setupFeeDiscountCampaignOption";
    FieldNames["TrainingLicenseValue"] = "trainingLicenseValue";
    FieldNames["TrainingLicenseDiscountCampaignOption"] = "trainingLicenseDiscountCampaignOption";
    FieldNames["PrepaidTabletsCount"] = "prepaidTabletsCount";
    FieldNames["AdditionalInformationText"] = "additionalInformationText";
    FieldNames["CreatedBy"] = "createdBy";
    FieldNames["RegistrationCode"] = "registrationCode";
    FieldNames["RegistrationLink"] = "registrationLink";
    return FieldNames;
}({});
