"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentLocale = void 0;
const i18n_1 = require("../i18n");
exports.getCurrentLocale = function getCurrentLocale() {
    var localeValue = localStorage.getItem(i18n_1.LOCAL_STORAGE_LANG_KEY);
    if (localeValue === 'zh-cn') {
        return 'cn';
    }
    return localeValue || 'en';
};
