"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../../../../../utils");
var submitHomework = function submitHomework(_ref) {
    var email = _ref.email, teamName = _ref.teamName, fileName = _ref.fileName, fileContent = _ref.fileContent, fileContentType = _ref.fileContentType;
    return utils_1.post({
        endpoint: 'mailer',
        payload: {
            to: email,
            templateName: 'submitHomework',
            href: 'https://www.quizrr.se',
            variables: {
                teamName: teamName
            },
            attachments: [{
                    Name: fileName,
                    Content: fileContent,
                    ContentType: fileContentType
                }]
        },
        withAuth: true
    });
};
exports.default = submitHomework;
