"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getFormattedSize = function getFormattedSize(bytes) {
    var units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    var size = bytes;
    var unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex += 1;
    }
    return "".concat(size.toFixed(2), " ").concat(units[unitIndex]);
};
exports.default = getFormattedSize;
