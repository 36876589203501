"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_FORMAT = exports.REPORT_VARIANT = exports.CERTIFICATE_TYPE = exports.REPORT_TYPE = exports.ERRORS = exports.REPORT_SUB_TYPE = void 0;
exports.REPORT_SUB_TYPE = {
    none: '',
    overall: 'overall',
    perQuestion: 'per-question',
    postTrainingFeedback: 'post-training-feedback'
};
exports.ERRORS = {
    noData: 'noData'
};
exports.REPORT_TYPE = Object.freeze({
    regular: 'regular-training',
    baselineTraining: 'baseline-training',
    factoryPerformance: 'factory-performance',
    programPerformance: 'program-performance',
    etiSurveys: 'ETI-surveys',
    surveys: 'surveys',
    trainingParticipation: 'training-participation',
    account: 'account',
    trainingProgress: 'training-progress',
    supplierTrainingReport: 'supplier-training-report'
});
exports.CERTIFICATE_TYPE = Object.freeze({
    facility: 'facility',
    player: 'player',
    all_players: 'all_players'
});
exports.REPORT_VARIANT = Object.freeze({
    go: 'go',
    core: 'core'
});
exports.REPORT_FORMAT = Object.freeze({
    csv: 'csv',
    xlsx: 'xlsx'
});
