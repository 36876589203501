"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilityTraningPerfomanceSteps = void 0;
exports.FacilityTraningPerfomanceSteps = [{
        content: 'joyride:facilities_training_performance.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-facilitiesTrainingPerformanceStats',
        maxWidth: '240px'
    }, {
        content: 'joyride:facility_training_performance_card.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'right',
        target: '.joyride-facilityPerformanceCard'
    }, {
        content: 'joyride:sort_bar.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-sortBar'
    }, {
        content: 'joyride:search_bar.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'auto',
        target: '.joyride-searchBar'
    }, {
        content: 'joyride:show_low_performing_facilities.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 10,
        placement: 'left',
        target: '.joyride-lowPerformingFacilitiesSwitch'
    }, {
        content: 'joyride:filter_facility_training_performance.content',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:back',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'right',
        target: '.joyride-filterFacilities'
    }];
