"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReminderType = void 0;
const utils_1 = require("../../../../../utils");
const i18n_1 = require("../../../../../i18n");
exports.ReminderType = function (ReminderType) {
    ReminderType["Invite"] = "invite";
    ReminderType["Reminder"] = "reminder";
    return ReminderType;
}({});
var sendReminderEmail = function sendReminderEmail(_ref) {
    var email = _ref.email, accountId = _ref.accountId, type = _ref.type;
    return utils_1.post({
        endpoint: 'user-accounts/remind-member',
        payload: {
            email: email,
            accountId: accountId,
            type: type
        },
        language: i18n_1.getLanguage()
    });
};
exports.default = sendReminderEmail;
