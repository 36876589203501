"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileSteps = void 0;
exports.UserProfileSteps = [{
        content: 'joyride:profile.content',
        locale: {
            skip: 'joyride:skip',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-profile'
    }];
