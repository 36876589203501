"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadQRCode = exports.prepareToConvertSvgToCanvas = exports.IMAGE_DIMENSION = void 0;
exports.IMAGE_DIMENSION = 450;
exports.prepareToConvertSvgToCanvas = function prepareToConvertSvgToCanvas() {
    var svgString = new XMLSerializer().serializeToString(document.getElementById('QR_Code'));
    var canvas = document.createElement('canvas');
    canvas.width = exports.IMAGE_DIMENSION;
    canvas.height = exports.IMAGE_DIMENSION;
    var DOMURL = window.URL || window.webkitURL || window;
    var img = new Image();
    var svg = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8'
    });
    var url = DOMURL.createObjectURL(svg);
    return {
        img: img,
        url: url,
        canvas: canvas,
        DOMURL: DOMURL
    };
};
exports.downloadQRCode = function downloadQRCode(linkToDownload) {
    var _prepareToConvertSvgT = exports.prepareToConvertSvgToCanvas(), img = _prepareToConvertSvgT.img, url = _prepareToConvertSvgT.url, canvas = _prepareToConvertSvgT.canvas, DOMURL = _prepareToConvertSvgT.DOMURL;
    img.onload = function () {
        canvas.getContext('2d').drawImage(img, 0, 0);
        var imgURL = canvas.toDataURL('image/png');
        DOMURL.revokeObjectURL(imgURL);
        var downloadLink = document.createElement('a');
        downloadLink.href = imgURL;
        downloadLink.download = linkToDownload;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    img.src = url;
};
