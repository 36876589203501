"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebounceTimeout = exports.ButtonStatus = void 0;
exports.ButtonStatus = function (ButtonStatus) {
    ButtonStatus["LOADING"] = "loading";
    ButtonStatus["SUCCESS"] = "success";
    ButtonStatus["FAIL"] = "fail";
    return ButtonStatus;
}({});
exports.DebounceTimeout = 1500;
