"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearAuthentication = exports.storeAuthentication = exports.getCurrentAccessToken = exports.getDemoAccessToken = exports.getAccessToken = exports.checkIsAuthenticated = void 0;
var StorageKey = function (StorageKey) {
    StorageKey["ACCESS_TOKEN"] = "QuizrrAuthToken";
    StorageKey["DEMO_ACCESS_TOKEN"] = "QuizrrDemoToken";
    return StorageKey;
}(StorageKey || {});
exports.checkIsAuthenticated = function checkIsAuthenticated() {
    return !!localStorage.getItem(StorageKey.ACCESS_TOKEN);
};
exports.getAccessToken = function getAccessToken() {
    return localStorage.getItem(StorageKey.ACCESS_TOKEN);
};
exports.getDemoAccessToken = function getDemoAccessToken() {
    return localStorage.getItem(StorageKey.DEMO_ACCESS_TOKEN);
};
exports.getCurrentAccessToken = function getCurrentAccessToken() {
    return exports.getDemoAccessToken() || exports.getAccessToken();
};
exports.storeAuthentication = function storeAuthentication(_ref) {
    var accessToken = _ref.accessToken, demoAccessToken = _ref.demoAccessToken;
    if (accessToken) {
        localStorage.setItem(StorageKey.ACCESS_TOKEN, accessToken);
    }
    if (demoAccessToken) {
        localStorage.setItem(StorageKey.DEMO_ACCESS_TOKEN, demoAccessToken);
    }
};
exports.clearAuthentication = function clearAuthentication() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}, _ref2$clearAccessToke = _ref2.clearAccessToken, clearAccessToken = _ref2$clearAccessToke === void 0 ? true : _ref2$clearAccessToke, _ref2$clearDemoAccess = _ref2.clearDemoAccessToken, clearDemoAccessToken = _ref2$clearDemoAccess === void 0 ? true : _ref2$clearDemoAccess;
    if (clearAccessToken) {
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
    }
    if (clearDemoAccessToken) {
        localStorage.removeItem(StorageKey.DEMO_ACCESS_TOKEN);
    }
};
