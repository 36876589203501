"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatus = exports.UserRole = void 0;
exports.UserRole = function (UserRole) {
    UserRole["ACCOUNT_OWNER"] = "account_owner";
    UserRole["ACCOUNT_USER"] = "account_user";
    UserRole["ACCOUNT_PLAYER"] = "account_player";
    UserRole["TEAM_ADMIN"] = "team_admin";
    UserRole["QUIZRR_EMPLOYEE"] = "quizrr_employee";
    UserRole["ADMIN"] = "admin";
    UserRole["LOCAL_MANAGER"] = "local_manager";
    UserRole["LOCAL_TRAINER"] = "local_trainer";
    return UserRole;
}({});
exports.UserStatus = function (UserStatus) {
    UserStatus["INVITE_PENDING"] = "invite_pending";
    UserStatus["REGISTERED"] = "registered";
    return UserStatus;
}({});
