"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileType = function (FileType) {
    FileType["PDF"] = "pdf";
    FileType["DOC"] = "msword";
    FileType["DOCX"] = "vnd.openxmlformats-officedocument.wordprocessingml.document";
    FileType["EXCEL"] = "vnd.ms-excel";
    FileType["EXCELX"] = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    return FileType;
}(FileType || {});
var getFileExtension = function getFileExtension(fileType) {
    switch (fileType) {
        case FileType.PDF:
            return 'pdf';
        case FileType.DOC:
            return 'doc';
        case FileType.DOCX:
            return 'docx';
        case FileType.EXCEL:
            return 'xls';
        case FileType.EXCELX:
            return 'xlsx';
        default:
            return '';
    }
};
exports.default = getFileExtension;
