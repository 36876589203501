"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilityGoSteps = void 0;
exports.FacilityGoSteps = [{
        content: 'joyride:summary.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-facilityGoScrollBlock .joyride-summaryFacilityGo'
    }, {
        content: 'joyride:training_performance.content',
        locale: {
            skip: 'joyride:skip',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'top',
        target: '.joyride-performance'
    }];
